// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-components-templates-blog-post-js": () => import("./../../../src/components/templates/blogPost.js" /* webpackChunkName: "component---src-components-templates-blog-post-js" */),
  "component---src-components-templates-index-4-category-js": () => import("./../../../src/components/templates/index4Category.js" /* webpackChunkName: "component---src-components-templates-index-4-category-js" */),
  "component---src-components-templates-index-4-tag-js": () => import("./../../../src/components/templates/index4Tag.js" /* webpackChunkName: "component---src-components-templates-index-4-tag-js" */),
  "component---src-components-templates-index-js": () => import("./../../../src/components/templates/index.js" /* webpackChunkName: "component---src-components-templates-index-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

